import React from 'react'

import { Box } from './styles'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onClick: () => void
  actived: boolean
  style?: React.CSSProperties
}

const BoxSelection = ({ children, onClick, actived, style }: Props) => (
  <Box onClick={onClick} actived={actived} style={style}>
    {children}
  </Box>
)

export default BoxSelection
