import * as React from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import ProgressBarSteps from '@components/organims/ProgressBarSteps'

import { Container, Content, ContainerFooter } from './styles'
import { PageProps } from './types'

const NovoPage = ({
  children,
  stickyFooter,
  footerTwoButtonsSpace = '120px',
  stickyContainerFooterBottom,
  positionFooter,
  style,
}: PageProps) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  return (
    <>
      <ProgressBarSteps />
      <Container id="page-container" style={style}>
        <Content isWeb={!isApp} id="page-content" footerTwoButtonsSpace={footerTwoButtonsSpace}>
          {children}
        </Content>
        {stickyFooter && (
          <ContainerFooter
            id="page-sticky-footer"
            stickyContainerFooterBottom={stickyContainerFooterBottom}
            positionFooter={positionFooter}
          >
            {stickyFooter}
          </ContainerFooter>
        )}
      </Container>
    </>
  )
}

export default NovoPage
