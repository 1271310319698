import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ConveniosCodes, ErrorCodes } from '@utils/enums'
import { showError } from '@store/ui/error/actions'
import * as actionsNovo from '@store/novo/fontePagamento/actions'
import { ApplicationState } from '@store/types'

interface OrgaoPayload {
  tipoSimulacao: 'novo'
  convenio: number
}

const actionsTypeSimulation = {
  novo: actionsNovo,
}

const errorCodesTypeSimulation = {
  novo: ErrorCodes.N0_002_ORGAOS,
  portabilidade: ErrorCodes.P0_006_ORGAOS,
}

export default function* getOrgaos(action: AnyAction) {
  const payload = action.payload as OrgaoPayload
  const { orgaosSuccess, orgaosRequest, orgaosError, setOrgaoSelecionado } =
    actionsTypeSimulation[payload.tipoSimulacao]

  try {
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    const response = yield* call(services.getOrgaos, payload.convenio, newSimulatorApi)
    const { orgaosPorConvenioConsulta } = response.data
    const data = Object.values(orgaosPorConvenioConsulta).filter(
      (value) => !!value && !!value?.codigo,
    )
    yield* put(orgaosSuccess(data))
    if (data.length === 1) {
      const [orgao] = data
      yield* put(setOrgaoSelecionado(orgao.codigo))
    } else if (payload.convenio === ConveniosCodes.PREF_SP) {
      // TODO: Após resolução de campo de orgão para pref. de SP, remover esse atribuição forçada.
      yield* put(setOrgaoSelecionado(22))
    }
  } catch (error) {
    yield* put(orgaosError())
    yield* put(
      showError({
        title: 'Erro ao buscar orgãos',
        message: 'Ocorreu um erro listagem de orgãos.',
        actionTitle: 'Tentar Novamente',
        code: errorCodesTypeSimulation[payload.tipoSimulacao],
        actionCallback: () => orgaosRequest(payload.convenio),
      }),
    )
  }
}
