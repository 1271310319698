import React, { useCallback, useEffect } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import InterWebviewBridge from '@interco/inter-webview-bridge'
import MobileBridge from '@interco/inter-mobile-bridge'
import routesNovo from '@routes/Privado/routes'
import routesNovoSimulador from '@routes/SimuladorNovo/routes'
import routesSimuladorApp from '@routes/SimuladorApp/routes'
import routesV3 from '@routes/SimuladorV3/routes'
import { isOldBridge } from '@services/base/oldBridge'
import { validaEnvHomeByBridge } from '@utils/functions'
import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'
import { FontesPagamento } from '@utils/enums'
import { setShowBottomsheetEvasao } from '@store/ui/navigation/actions'
import Tag from '@utils/Tags'

interface AppGoBackType {
  App: {
    goBack: () => void
  }
}
export type WindowMobileType = AppGoBackType & typeof window

const tagEnabledRoutes = [
  routesSimuladorApp.ONBOARDING,
  routesSimuladorApp.RESULTADO_SIMULACAO,
  routesSimuladorApp.RESUMO,
  routesSimuladorApp.DADOS_RECEBIDOS,
]

export const GoBackConfig = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const iWb = InterWebviewBridge.getInstance()
  const Window = window as WindowMobileType
  const { isApp, origemGoBack } = useSelector((state: ApplicationState) => state.ui.navigation)

  const { fonteSelecionada } = useSelector((state: ApplicationState) => state.novo.fontePagamento)

  const { sangueLaranja } = useSelector((state: ApplicationState) => state.novo.dadosPessoais)

  const callSangueLaranjaTag = useCallback(() => {
    if (sangueLaranja && tagEnabledRoutes.includes(location?.pathname as routesSimuladorApp)) {
      const product =
        location?.pathname === routesSimuladorApp.RESUMO ||
        location?.pathname === routesSimuladorApp.DADOS_RECEBIDOS
          ? 'CONSIG_SANGUELARANJA_CONTRATACAO'
          : 'CONSIG_SANGUELARANJA_SIMULACAO'

      Tag.simuladorConsignadoTag({
        tela: location?.pathname,
        item_name: 'Consignado Sangue Laranja',
        content_name: 'back',
        content_action: 'toque',
        product,
        name: 'HEADER',
      })
    }
  }, [sangueLaranja, location?.pathname])

  const listenerMobileBack = useCallback(() => {
    callSangueLaranjaTag()
    if (
      location?.pathname === routesV3.HOME ||
      location?.pathname === routesNovo.ONBOARDING ||
      location?.pathname === routesNovoSimulador.CALCULADORA ||
      location?.pathname === routesNovoSimulador.ONBOARDING ||
      location?.pathname === routesSimuladorApp.ONBOARDING ||
      (location?.pathname === routesSimuladorApp.TERMOS &&
        fonteSelecionada === FontesPagamento.INSS) ||
      location?.pathname === routesSimuladorApp.SELECAO_GRUPO
    ) {
      if (origemGoBack === 'emprestimo' || origemGoBack === 'emprestimoinss') {
        window.location.href = validaEnvHomeByBridge(isApp) || ''
      } else {
        BridgeService.backToNative()
      }
    } else if (location?.pathname === routesV3.RESULTADO_SIMULACAO) {
      dispatch(setShowBottomsheetEvasao(true))
    } else {
      history.goBack()
    }
  }, [
    dispatch,
    fonteSelecionada,
    history,
    isApp,
    location?.pathname,
    origemGoBack,
    callSangueLaranjaTag,
  ])

  useEffect(() => {
    if (isOldBridge()) {
      Window.App = {
        goBack: () => {
          try {
            callSangueLaranjaTag()
            if (
              location?.pathname === routesV3.HOME ||
              location?.pathname === routesNovo.ONBOARDING ||
              location?.pathname === routesNovoSimulador.CALCULADORA ||
              location?.pathname === routesNovoSimulador.ONBOARDING ||
              (location?.pathname === routesSimuladorApp.TERMOS &&
                fonteSelecionada === FontesPagamento.INSS) ||
              location?.pathname === routesSimuladorApp.SELECAO_GRUPO
            ) {
              if (origemGoBack === 'emprestimo' || origemGoBack === 'emprestimoinss') {
                window.location.href = validaEnvHomeByBridge(isApp) || ''
              } else {
                MobileBridge.goBack()
              }
            } else {
              history.goBack()
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
          }
        },
      }
    } else {
      iWb.addWebviewEventListener('GO_BACK', listenerMobileBack)
      return () => {
        iWb.removeWebviewEventListener('GO_BACK', listenerMobileBack)
      }
    }
    return () => null
  }, [
    Window,
    fonteSelecionada,
    history,
    iWb,
    isApp,
    listenerMobileBack,
    location?.pathname,
    origemGoBack,
    callSangueLaranjaTag,
  ])

  return <></>
}
