import { BaseServices, HttpMethod } from '@services/base'

export default (data: TrackingSimulacaoRequest, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_TRACKING_SIMULACAO
    : process.env.REACT_APP_TRACKING_SIMULACAO_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<void>({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface TrackingSimulacaoRequest {
  eventType?: string
  session?: string
  origem?: string
  step?: string
  usuarioToken?: string
  tipoSimulacao?: string
  quantidadeParcelas?: number
  valorParcela?: number
  valorFinanciado?: number
  valorLiberado?: number
  simulacaoMotivo?: string
  salarioBruto?: number | string
  tempoEmpresa?: string | number
  matricula?: string | number
  convenio?: string | number
  tipoConvenio?: string | number
  orgao?: string | number
  familia?: string
  descricaoConvenio?: string
  descricaoFamilia?: string
  descricaoOrgao?: string
  dataNascimento?: string
  autorizaConsulta?: boolean
  score?: number
  creditoProtegido?: boolean
  cartaoConsignado?: boolean
  convenioId?: number
  numeroControleCIP?: string
  autarquiaEmpresa?: string
  codigoOrgao?: string
  descricao?: string
  tipo?: string
  campanha?: string
  margem?: number
  salario?: number
  valorEmprestimo?: number
  cpfRestrito?: boolean
  qtdParcelas?: number
  taxa?: number
  valorCET?: number
  valorIOF?: number
  valorCartaoConsignado?: number
  empresa?: string
  autorizaINSS?: boolean
  nome?: string
  cpf?: string
  valorSolicitado?: number
  dataUltimoVencimento?: string
  tIRAM?: number
  createdIn?: Date | string
  flagTitularDados?: boolean
  flagInfoCredito?: boolean
  produto: string
  documento: string
}
